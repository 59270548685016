/**
 * Swup initialization, front-end only
 */
import Swup from 'swup';
import { createHistoryRecord, fetch, getDataFromHtml } from 'swup';
import SwupHeadPlugin from '@swup/head-plugin';
import SwupGtmPlugin from '@swup/gtm-plugin';
import SwupBodyClassPlugin from '@swup/body-class-plugin';
import SwupScriptsPlugin from '@swup/scripts-plugin';
import SwupPreloadPlugin from '@swup/preload-plugin';
import MmaqTheme from './swupMmaqTheme.js';
// import SwupDebugPlugin from '@swup/debug-plugin';

import 'zoom-vanilla.js/dist/zoom-vanilla.min.js';

const initZoomImages = () => {
    const single_images = document.querySelectorAll('.single-post-content img, .blocks-gallery-item img');
    for (let i = 0, il = single_images.length; i < il; ++i) {
        const single_image = single_images[i];
        if (!single_image.getAttribute('src').match(/\.(gif)$/i)) {
            single_image.setAttribute('data-action', 'zoom');
        }
    }
};

/**
 * iframe resize doesn't respond sometimes because qidigo loads content through ajax
 * without bubbling an event to us, so we need to check if reszie worked and refire the
 * code in an interval until the iframe has actually resized.
 */
const setupIframeResize = () => {
    const iframe = document.querySelector('.qidigo-iframe');
    let interval = 0;
    if (iframe) {
        const initialHeight = iframe.getBoundingClientRect().height;
        // iframe.addEventListener('load', () => {
        interval = window.setInterval(() => {
            if (typeof iframe.iFrameResizer !== 'undefined') {
                iframe.iFrameResizer.removeListeners();
            }
            const resizer = iFrameResize({ log: true, bodyBackground: 'transparent' }, iframe);
            if (iframe.getBoundingClientRect().height !== initialHeight) {
                window.clearInterval(interval);
            }
        }, 1000);
        // })
    }
};

let swupInstance = null;
const swupContainers = [];
const swupAppMain = '#app-main';
const swupFooter = '#swup-footer';
const swupAdminBar = '#swup-admin-bar';
if (document.querySelector(swupAppMain)) {
    swupContainers.push(swupAppMain);
}
if (document.querySelector(swupFooter)) {
    swupContainers.push(swupFooter);
}
if (document.querySelector(swupAdminBar)) {
    swupContainers.push(swupAdminBar);
}
if (!document.body.classList.contains('wp-admin')) {
    swupInstance = new Swup({
        // cache: false,
        // animateHistoryBrowsing: true,
        animationSelector: '[class*="page-transition-"]',
        containers: swupContainers,
        plugins: [
            new SwupPreloadPlugin(),
            new MmaqTheme({
                duration: 750,
            }),
            new SwupScriptsPlugin({
                optin: false, // https://swup.js.org/plugins/scripts-plugin
            }),
            new SwupHeadPlugin({
                persistTags: 'link[href*="/app/uploads/complianz/css/banner"]',
            }),
            new SwupBodyClassPlugin(),
            // new SwupGtmPlugin()
        ],
        // debugMode: true,
        // admin bar wordpress : :not(.ab-item)
        linkSelector: `
          a[href^="${window.location.origin}"]:not([data-no-swup]):not(.ab-item):not([href^="#nogo"]),
          a[href^="/"]:not([data-no-swup]):not(.ab-item):not([href^="#nogo"]),
          a[href^="#"]:not([data-no-swup]):not(.ab-item):not([href^="#nogo"])
      `,
        skipPopStateHandling(event) {
            return !(event.state && event.state.source === 'swup');
        },
    });
}

// window.addEventListener('loadform', (ev) => {
//     document.documentElement.classList.add('cursor-wait');
//     // console.log(ev.detail);
//     let base = window.location.origin + window.location.pathname
//     let string = new URLSearchParams(new FormData(document.querySelector('.js-loadmore-form'))).toString()
//     if(string !== '') {
//         string = '?' + string;
//     }
//     let urltoload = base + string;

//     // console.log(urltoload);
//     fetch({
//         'url': urltoload
//     }, (response) => {
//         document.documentElement.classList.remove('cursor-wait');
//         createHistoryRecord(urltoload)
//         let res = getDataFromHtml(response.response, ['.js-loadmore-container']);
//         // console.log(res);
//         if(res && res.blocks.length > 0) {
//             for (let i=0, il=res.blocks.length; i < il; ++i) {
//                 let block = res.blocks[i]
//                 document.querySelector('.js-loadmore-container').outerHTML = block;
//             }
//         }else{
//             document.querySelector('.js-loadmore-container').innerHTML = '';
//         }
//     })
// });
window.addEventListener('urlchange', (ev) => {
    // swupInstance.loadPage({
    //     url:ev.detail,
    //     customTransition:'fade'
    // });
    if (swupInstance) {
        swupInstance.loadPage({
            url: ev.detail,
        });
    }
});

window.addEventListener('DOMContentLoaded', () => {
    setupIframeResize();
    initZoomImages();
});

if (swupInstance) {
    swupInstance.hooks.on('content:replace', () => {
        // scroll back up or move to element
        // if (window.location.hash !== '') {
        //     const el = document.querySelector(window.location.hash);
        //     if (el) {
        //         el.scrollIntoView();
        //     }
        // } else {
        //     window.scrollTo(0, 0);
        // }

        // Compat with advanced forms
        // if (window.acf) {
        //     window.acf.doAction('ready');
        // }

        setupIframeResize();
        // Add zoom on images
        initZoomImages();
    });
}
